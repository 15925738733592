/* .focused-chat-item {
    border: 3px solid blue !important;
  }

  .focused-aria-item{
    border: 3px solid blue !important;
  }
  .webchat__basic-transcript__scrollable .ac-container.ac-input-container.ac-input.focused-aria-item{
    border: 3px solid blue !important;
  }

  .focused-input-item{
    border: 3px solid red !important;
  }
  .focused-button-item{
    border: 3px solid red !important;
  }
   */



  .focused-label-item{
    border: 3px solid red !important;
  }

  .focused-chat-item {
    border: 2px solid red !important;
    outline: none;
  }
  
  .focused-aria-item {
    border: 2px solid blue !important;
    outline: none;
  }
  
  .focused-input-item {
    border: 2px solid green !important;
    background: black !important;
    outline: none;
  }
  
  .focused-button-item {
    border: 2px solid purple !important;
    outline: none;
  }
  

  /* Ensure these rules are in your styles.css */

.webchat__basic-transcript__scrollable .focused-chat-item {
  border: 2px solid blue !important;
}

.webchat__basic-transcript__scrollable .focused-aria-item,
.webchat__basic-transcript__scrollable .ac-richTextBlock .focused-aria-item,
.webchat__basic-transcript__scrollable .ac-input-container .focused-aria-item {
  border: 2px solid blue !important;
  /* background: black !important; */
}


