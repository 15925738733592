@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800&display=swap');

.chat-container {
	font-family: inherit;
	-webkit-font-smoothing: auto !important;
}
.content {
	font-family: inherit;
}
.chat-container .css-1b7yvbl,
.chat-container [data-css-1b7yvbl] {
	font-family: inherit;
}
:focus {
	outline: none !important;
}

.passwordInput {
	background-color: Red;
	border-radius: 3px;
	color: White;
	display: flex;
	font-family: Calibri, 'Helvetica Neue', Arial, sans-serif;
	margin: 10px;
	padding: 5px;
}

.passwordInput .passwordInput__box {
	display: flex;
	flex: 1;
}

.passwordInput .passwordInput__label {
	padding: 10px;
}

.passwordInput .passwordInput__input {
	border: 0px;
	border-radius: 3px;
	flex: 1;
	letter-spacing: 0.5em;
	outline: 0;
	padding: 0 10px;
	width: 100%;
}

.passwordInput .passwordInput__input:disabled {
	background-color: rgba(255, 255, 255, 0.5);
	color: White;
}

.chat-container {
	/* height: 560px !important;
    min-height: 464px !important; */
	background: #fff !important;
	-webkit-box-shadow: 2px 9px 16px 4px rgba(0, 121, 251, 0.08);
	-moz-box-shadow: 2px 9px 16px 4px rgba(0, 121, 251, 0.08);
	box-shadow: 2px 9px 16px 4px rgba(0, 121, 251, 0.08);
	position: fixed;
	right: 17px;
	border-radius: 8px;
	overflow: hidden;
	bottom: 109px;
	transition: all 0.4s;
	z-index: 9;
	width: 480px;
	height: 580px;
}
.fullwidget_v2{
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.fullwidget_v2.chat-container div[role="complementary"] {
	background-image:none !important;
    background-color: #fff !important;
}
.fullwidget_v2.chat-container .webchat__bubble{
    box-shadow: none !important;
}

.chat-container img,
.chat-container svg {
	vertical-align: initial;
}

.chat-container.maximize-bot {
	width: 60%;
	height: calc(100% - 20%) !important;
	transition: all 0.4s;
}
/* .chat-container>.chat-header {
    background-color: #162D7C;
    position: relative;
} */

.chat-container > .chat-header {
	/* background: #1e90ff; 
  background: -moz-linear-gradient(
    left,
    #1e90ff 0%,
    #2b90f1 42%,
    #0a67c2 100%
  );
  background: -webkit-linear-gradient(
    left,
    #1e90ff 0%,
    #2b90f1 42%,
    #0a67c2 100%
  ); 
  background: linear-gradient(
    to right,
    #1e90ff 0%,
    #2b90f1 42%,
    #0a67c2 100%
  ); */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e90ff', endColorstr='#0a67c2',GradientType=1 ); /* IE6-9 */
	position: relative;
	height: 52px;
	box-shadow: 0px 0px 12px 5px rgba(0, 121, 251, 0.08);
}
/* span.notify.disconnect {
    display: none;
} */

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

span.restart-icon img {
	width: 18px;
	position: relative;
	top: 1px;
}
.webchat__bubble__content ul li {
	margin-bottom: 1px;
}
span.bot-name {
	font-size: 16px;
	color: #ffffff;
}
.content {
	margin-bottom: 16px;
}
.chat-header {
	padding: 0px 11px;
	padding-top: 15px;
}

span.header-icons {
	padding-top: 0px;
}

.ac-container.ac-adaptiveCard {
	background-color: transparent !important;
	box-shadow: none !important;
	/* padding: 3px !important; */
	border: 0px !important;
}

.ac-actionSet .ac-pushButton {
	margin-right: 5px;
	margin-bottom: 12px;
	border-radius: 50px;
	/* font-weight: 300 !important; */
}

.ac-actionSet {
	flex-flow: wrap !important;
}

.ac-pushButton {
	appearance: none;
	background-color: transparent !important;
	border-style: solid;
	border-width: 1px;
	color: #1e90ff !important;
	font-weight: 500;
	padding: 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	border-color: #1e90ff !important;
	font-size: 14px;
}

.ac-pushButton:focus {
	outline: none !important;
}

.chat-container div[role='complementary'] {
	background-color: #f0f6ff !important;
	/* height: calc(100% - 52px) !important; */
	width: 100%;
	height: calc(100% - 83px) !important;
	transition: all 0.5s;
	padding-bottom: 48px;
} 

.css-eycyw2.css-1882x3w.css-1l9a57c {
	background: #fff;
	box-shadow: 0px 0px 12px 5px rgba(0, 121, 251, 0.08);
}

.css-eycyw2 > .main {
	align-items: stretch;
	background-color: White;
	min-height: 52px !important;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	box-shadow: 0px 1px 14px 1px rgba(0, 121, 251, 0.08) !important;
	border: 0px !important;
	padding-top: 0px !important;
	padding-left: 0px !important;
}

form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc input {
	border-bottom: 1px solid #c9cdd1;
	color: #6f7d8c !important;
}

.css-115fwte svg {
	fill: #839bb3;
	font-size: 25px !important;
	width: 33px;
	margin-top: 4px;
}

.css-n2zczk,
[data-css-n2zczk] {
	padding-right: 2px !important;
}

/* .webchat__bubble__content {
  background: transparent !important;
  border: 0px !important;
  color: #6f7d8c !important;
  font-size: 14px;
} */
/* .ac-container p {
    color: #6f7d8c;
    font-weight: 400;
} */
/* .chat-container .ac-container:first-child > div:first-child>p:first-child{
    font-weight: 400 !important;
} */
.webchat__basic-transcript__activity--from-user .webchat__bubble .ac-container p {
	color: #fff;
}
.webchat__bubble {
	margin-left: 19px;
}

/* .bubble :after {
    position: absolute;
    top: 10px;
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
} */

.webchat__basic-transcript__activity--from-user .webchat__bubble {
	background: #e5eefc;
	border-radius: 5px;
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
	margin-right: 12px;
}

.timestamp.transcript-timestamp {
	margin-left: 17px;
}

.webchat__basic-transcript__activity--from-user .timestamp.transcript-timestamp {
	margin-right: 14px;
}

span.chat-options img {
	width: 6px;
}
span.restart-icon {
	margin-right: 12px !important;
	position: relative;
	top: -1px;
}
.svg-inline--fa.fa-w-14 {
	width: 0.875em;
	font-size: 17px;
	vertical-align: -2px;
}

.exp-menu-frame {
	position: absolute;
	right: 0;
	background: #fff;
	width: 100%;
	left: 0;
	min-height: 300px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	z-index: 2;
	padding: 0 15px;
	height: 303px;
	overflow: scroll;
}
.menuItem {
	padding: 18px 0;
	padding-bottom: 0px;
}
.menuItem svg {
	margin-right: 10px !important;
	color: #839bb3;
}
.menuItem img {
	width: 19px;
	margin-right: 9px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.css-gtdio3.css-mfy564 {
	padding-bottom: 56px;
}
.webchat__send-box__main {
	bottom: 0;
	position: absolute;
	width: 100%;
	min-height: 52px !important;
	padding: 2px !important;
}
.click-begin {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	color: #fff;
}
.click-begin span:hover {
	cursor: pointer;
}
.click-begin span {
	background: #1e90ff;
	display: inline-block;
	width: 100%;
	padding: 17px 0;
	font-size: 14px;
}
.ac-container p {
	/* color: #6f7d8c; */
	/* font-size: 14px;
    font-weight: 400; */
	font-family: inherit;
}
.webchat__bubble__content p {
	font-family: inherit;
	margin-bottom: 5px;
}
.ac-actionSet .ac-pushButton {
	font-family: inherit;
}
input.ac-input,
select.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact {
	height: 35px;
	border-radius: 5px;
	border: 0px;
	color: #6f7d8c;
	font-size: 14px;
	padding: 0px 10px;
	background-color: #fff;
}
.ac-input.ac-choiceSetInput-expanded {
	padding: 1px;
	border: 0px !important;
}
textarea.ac-input.ac-textInput.ac-multiline {
	height: 78px;
	font-size: 14px;
	padding: 5px 10px;
	border: 0px;
	border-radius: 5px;
	color: #6f7d8c;
}
.menuItem span {
	color: #6f7d8c;
	font-size: 12px;
}

.scaleup {
	position: absolute !important;
	width: 0px !important;
	right: -100% !important;
	display: block;
	left: auto !important;
	transition: all 0.4s;
}

.transform-in {
	width: 250px !important;
	transition: all 0.4s;
	right: 0 !important;
}
.notify {
	position: relative;
	top: 2px;
	right: -10px;
}
.notify .beats {
	position: absolute;
	top: -14px;
	right: 3px;
	height: 12px;
	width: 12px;
	z-index: 10;
	border: 7px solid #16ca03;
	border-radius: 55px;
	-moz-animation: beats 1s ease-out;
	-moz-animation-iteration-count: infinite;
	-o-animation: beats 1s ease-out;
	-o-animation-iteration-count: infinite;
	-webkit-animation: beats 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
.notify .point {
	width: 6px;
	height: 6px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background-color: #16ca03;
	position: absolute;
	top: -10px;
	right: 7px;
}
.notify.disconnect .point {
	width: 6px;
	height: 6px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background-color: red;
	position: absolute;
	top: -5px;
	right: 8px;
}

span.restart-icon.live-chat {
	position: relative;
	margin-right: 8px;
}

@-moz-keyframes beats {
	0% {
		-moz-transform: scale(0);
		opacity: 0;
	}
	25% {
		-moz-transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		-moz-transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		-moz-transform: scale(0.8);
		opacity: 0.5;
	}
	100% {
		-moz-transform: scale(1);
		opacity: 0;
	}
}

@-webkit-keyframes beats {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
	}
	25% {
		-webkit-transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		-webkit-transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		-webkit-transform: scale(0.8);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 0;
	}
}

.css-1tdb3h1.image {
	height: 40px;
	width: 35px !important;
}
svg.svg-inline--fa.fa-expand-alt.fa-w-14 {
	font-size: 17px;
	position: relative;
	/* top: -1px; */
	vertical-align: -2px;
}
.ac-pushButton:hover {
	background: #1e90ff !important;
	color: #fff !important;
	border-color: #1e90ff !important;
	transition: all 0.4s;
	cursor: pointer;
}
span.header-icons span:hover {
	cursor: pointer;
}
.ac-pushButton.style-default {
	min-width: 90px;
}
.locale-select-con-value {
	color: #fff;
	padding: 4px;
}

.close-bot {
	bottom: -500% !important;
}
.chat-container select.locale-select-con {
	height: 21px;
	border: 0px !important;
	position: relative;
	top: -2px;
	padding: 0 4px;
	font-weight: 600;
}
.chat-container select {
	font-size: 10px !important;
}
.chat-container .click-begin.gray-out span {
	background-color: #f6f5f9 !important;
}
span.itemName:hover {
	cursor: pointer;
}
.chat-container .webchat__bubble {
	background: #fff;
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	padding: 20px;
}
.chat-container .ac-input {
	font-size: 14px !important;
	padding: 0px 13px !important;
	min-height: 40px !important;
	border-radius: 5px !important;
}
.chat-container select.ac-input {
	font-size: 14px !important;
}
.chat-container .ac-actionSet .ac-pushButton {
	padding: 6px 12px;
}
.chat-container .markdown {
	box-shadow: none;
	padding: 0;
}
.chat-container .webchat__bubble__content {
	min-height: auto !important;
	border-style: none !important;
	border: 0px;
}
.chat-container .webchat__bubble__content p {
	padding: 0;
}
.chat-container .ac-container {
	margin-top: 2px !important;
}
.chat-container .ac-horizontal-separator {
	margin-top: 6px;
}
.exp-menu-frame .menuItem {
	text-align: left;
}
.exp-menu-frame {
	min-height: 95px;
	height: auto !important;
	overflow: auto;
	box-shadow: 0px 1px 14px 1px rgba(0, 121, 251, 0.08) !important;
}
.chat-container .webchat__bubble {
	box-shadow: 0px 1px 14px 1px rgba(0, 121, 251, 0.08) !important;
	padding: 10px 18px;
	margin: 1px 15px 3px;
	width: auto !important;
	max-width: initial !important;
}
.chat-container .webchat__bubble__content p {
	padding: 0 !important;
	margin-bottom: 0px !important;
	min-height: auto !important;
	white-space: break-spaces !important;
}

form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc input {
	font-family: inherit;
	padding-bottom: 8px;
}
.chat-container .main form input {
	font-family: inherit;
	padding-bottom: 8px;
}

/* .chat-container .webchat__row.attachment {
        display: inline-block !important;
} */
.chat-container .from-bot-botname {
	margin-top: 0px !important;
	margin-left: 5px !important;
	font-size: 11px;
	color: #999;
	display: block;
}
.chat-container .from-user-botname {
	margin-right: 14px;
	margin-top: 0px !important;
	font-size: 11px;
	color: #999;
	float: right;
}
.chat-container .ac-actionSet .ac-pushButton {
	padding: 6px 8px !important;
	font-size: 13px;
}
.ac-container .ac-textBlock {
	display: block !important;
	/* flex: auto !important; */
	flex-wrap: wrap !important;
}
.chat-container .ac-container .ac-textBlock {
	display: block !important;
	/* flex: auto !important; */
	flex-wrap: wrap !important;
	max-height: 100% !important;
	width: 100%;
}
/* .chat-container .ac-container:first-child > div:first-child > p:first-child {
    color: #6f7d8c;
    font-size: 16px;
}    */
/* .chat-container .ac-container {
    margin-top: 2px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
} */
.chat-container button.css-1m1alj7 {
	position: sticky;
	float: right;
	background-color: #3b96f2;
}
.chat-container .menuItem {
	padding: 9px 15px;
	padding-bottom: 8px;
}
.chat-container .menuItem:last-child {
	padding-bottom: 15px;
}
.chat-container .exp-menu-frame {
	padding: 0 0 !important;
	min-height: auto !important;
}
.chat-container .webchat__basic-transcript__activity-body ul li .webchat__bubble__content {
	background: #fff !important;
	border-radius: 5px;
	position: relative;
	left: 13px;
	padding: 10px 16px !important;
	width: 385px;
}

/* .chat-container table.ac-factset tr td:first-child {
    width: 152px;
    padding-bottom: 5px !important;
} */
/* .chat-container table.ac-factset tr td:first-child p {
    font-weight: 600 !important;
    font-size: 14px;
}         */
.chat-container .ac-input-container {
	width: 100% !important;
}
.chat-container input.ac-input.ac-dateInput {
	min-height: auto !important;
	padding: 10px 3px !important;
}
.chat-container .css-1eu9xwl.avatar {
	background-color: transparent !important;
}
.chat-container [type='radio']:checked,
.chat-container [type='radio']:not(:checked) {
	position: relative !important;
	left: 0 !important;
	top: -2px !important;
}
.chat-container .ac-input [type='radio']:checked,
.chat-container [type='radio']:not(:checked) {
	position: relative !important;
	left: 0px !important;
	top: -2px !important;
}
.chat-container [type='checkbox']:checked,
.chat-container [type='checkbox']:not(:checked) {
	position: relative !important;
	left: 0 !important;
	top: -3px !important;
}
.chat-container .ac-input.ac-choiceSetInput-multiSelect {
	border: 0px !important;
}
.chat-container.ac-input.ac-toggleInput {
	border: 0px !important;
}
.chat-container .ac-input.ac-toggleInput {
	min-height: auto !important;
	border: none !important;
}
/* .chat-container .ac-container:first-child > div:first-child > p:first-child {
  font-weight: 700 !important;
} */

/* .chat-container .ac-container .ac-container{
    display: block !important;
} */

.powered_by {
	position: absolute;
	bottom: 60px;
	width: 100%;
	text-align: center;
	font-size: 13px;
}
.chat-container a.powerdBy {
	color: #bbbbbb !important;
}

/* css for new MessageButton */

.chat-container button.css-1m1alj7 {
	font-size: 0px;
	height: 35px;
	width: 35px;
	border-radius: 100px;
	opacity: 0.5;
	position: relative !important;
}
.chat-container button.css-1m1alj7:after {
	position: absolute;
	content: '\2039';
	width: 40px;
	background: transparent;
	z-index: 9;
	color: #fff;
	font-size: 34px;
	top: -7px;
	right: 1px;
	transform: rotate(270deg);
}
.chat-container button.css-1m1alj7:hover {
	opacity: 1;
	transition: auto;
}
.ac-container .ac-textBlock ul {
	padding-left: 40px !important;
	list-style: disc !important;
}
.chat-container .markdown ul {
	/* padding: 43px !important; */
	list-style: disc !important;
}
.ac-container.ac-adaptiveCard {
	background-color: transparent !important;
	box-shadow: none !important;
	border: 0px !important;
	padding: 0px !important;
}
.chat-container .ac-media-playButton {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.chat-container .webchat__bubble__content p {
	font-family: inherit;
	font-weight: inherit;
}
.ac-container .ac-textBlock {
	color: #6f7d8c;
}
.ac-container p {
	color: inherit;
}

/* last changes */

form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc input {
	border: 1px solid #e2e2e2;
	border-radius: 50px;
	padding: 8px 17px;
}
form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc {
	padding: 0 12px !important;
}

.webchat__icon-button svg {
	fill: #fff !important;
	font-size: 25px !important;
	width: 33px;
	/* margin-top: 4px; */
}
.webchat__icon-button svg {
	fill: #fff !important;
	font-size: 23px !important;
	width: 33px;
	position: relative;
	/* top: -2px; */
	/* right: -2px; */
}
.webchat__send-box__main .webchat__send-box__button {
	/* background: #3b96f2;
  background-color: #3b96f2; */
	height: 38px;
	position: relative;
	top: 6px;
	right: 0px !important;
	border-radius: 100px;
}
.css-qprcmg {
	position: absolute;
	bottom: 45px;
	left: 0px;
}

.chat-container .css-1lzfo2b .webchat__icon-button svg {
	width: 100%;
	right: 0px;
	left: 0px;
}
.chat-container .css-1lzfo2b .webchat__icon-button svg:hover {
	cursor: pointer;
}
.chat-container .css-1lzfo2b .webchat__icon-button svg {
	right: 0px;
}
.chat-container .css-1b7yvbl,
.chat-container [data-css-1b7yvbl] {
	font-family: 'Open Sans', sans-serif !important;
}
.chat-container select.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact {
	font-family: 'Source Sans Pro', sans-serif !important;
}
.chat-container .webchat__bubble__content p {
	font-family: 'Open Sans', sans-serif !important;
}
.chat-container .ac-container label.ac-textBlock {
	width: auto !important;
}
.chat-container .ac-actionSet .ac-pushButton div {
	white-space: normal !important;
	text-overflow: initial !important;
	text-align: center;
}
@media screen and (max-width: 1024px) {
	.chat-container.maximize-bot {
		width: 85% !important;
		height: calc(100% - 20%) !important;
		transition: all 0.4s;
	}
	.chat-container {
		width: 480px;
		height: calc(100% - 20%) !important;
	}

	.ap-client.chat-container.maximize-bot { 
		width: 100% !important;
		height: 100% !important;
		transform: unset !important;
		right: unset !important;
	}
}

@media screen and (max-width: 640px) {
	.chat-container.maximize-bot {
		width: 95% !important;
		height: calc(100% - 20%) !important;
	}
	.chat-container {
		width: 95%;
		height: calc(100% - 199px) !important;
		right: 10px;
	}
}
.chat-container blockquote {
	font-size: 15px !important;
}
.chat-container td.ac-fact-value a:hover {
	text-decoration: none !important;
}

.chat-container td.ac-fact-value a {
	color: #000 !important;
	cursor: auto;
}
.chat-container .btn-close {
	display: none;
}
.chat-container.maximize-bot .webchat__bubble {
	width: auto;
	max-width: inherit !important;
}
.chat-container .ac-actionSet .ac-pushButton div {
	text-overflow: initial !important;
	white-space: normal !important;
	text-align: center;
}
a.ac-anchor img {
	width: 80px;
}
.webchat__basic-transcript__activity--from-user .webchat__bubble__content p.plain {
	text-align: left !important;
}
/* IE Specific style */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.chat-container .ac-textBlock p {
		width: 100% !important;
		word-break: normal !important;
	}

	.chat-container .webchat__bubble {
		min-width: 277px !important;
	}
	.chat-container .css-a4zgb2.css-19ajsax.from-user.webchat__bubble_has_nub.bubble {
		width: auto !important;
		min-width: 40px !important;
	}
	.chat-container form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc input {
		position: absolute;
		top: 6px;
		width: calc(100% - 117px);
	}
	.chat-container .ac-container.ac-adaptiveCard {
		display: block !important;
	}
	.chat-container .ac-container {
		display: block !important;
	}
}
@media all and (-ms-high-contrast: none), all and (-ms-high-contrast: active) {
	.chat-container .webchat__bubble {
		min-width: 50px !important;
	}
	.chat-container .ac-actionSet {
		display: flex !important;
		width: 100% !important;
	}
	.chat-container.chat-widget-full.chat-widget-flow span.restart-icon img {
		top: -2px !important;
		right: -4px;
	}
}

/* css for color theme slector */
.chat-container span.color-selector {
	width: 20px;
	height: 20px;
	display: inline-block;
	background: #fff;
	margin: 4px;
	border-radius: 100%;
}
.chat-container .theme-selector {
	display: none;
}
.chat-container span.dark-theme {
	background: rgb(22, 26, 43) !important;
}
.chat-container form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc input {
	height: auto !important;
}
/* .bot-open.bo-v2 .bot-btn.ch-tab span.close-bot img {
    width: 16px !important;
    margin: 0px 14px !important;
  }
  body .bot-open.bo-v2 .bot-btn img {
    width: 62px !important;
    margin: -1px -2px !important;
  } */
.chat-container .ac-container .ac-textBlock img {
	width: auto !important;
	max-width: 100% !important;
}
.btn-nimimize-icon {
	display: none;
}

.welcome-user {
	position: fixed;
	width: 194px;
	right: 15px;
	bottom: 93px;
	background: rgb(59, 142, 190);
	padding: 9px 10px;
	border-radius: 10px;
	/* box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 10%); */
	color: #fff !important;
	display: none;
}

.welcome-user p {
	margin-top: 0;
	color: #fff;
	font-size: 13px;
	margin-bottom: 0px;
}

.welcome-user p:after {
	position: absolute;
	left: auto;
	content: '';
	width: 0px;
	height: 0px;
	top: auto;
	border-width: 11px;
	border-style: solid;
	border-image: initial;
	border-color: rgb(59, 142, 190) transparent transparent transparent;
	bottom: -20px;
	right: 21px;
}
span.bot-header-text {
	display: inline-block;
	position: relative;
	margin-right: 25px;
	color: white;
	font-size: 16px;
	width: auto !important;
}

body .chat-container.chat-widget-full span.header-icons.pull-right span.restart-icon.live-chat {
	background: #f1c40f !important;
}

span.activityStatus.activityStatus__timestamp {
	margin-left: 5px;
	font-size: 11px;
	color: #999;
	display: block;
}

.webchat__send-box-text-box .webchat__send-box-text-box__input {
	border: 0px;
	font-family: inherit;
	font-size: inherit;
	height: 100%;
	outline: 0px;
}
.webchat__send-box-text-box input.webchat__send-box-text-box__input {
	padding-bottom: 8px;
	border: 1px solid #e2e2e2 !important;
	padding: 8px 17px !important;
	height: auto !important;
	border-radius: 50px;
	border-bottom: 1px solid #c9cdd1;
	color: #6f7d8c !important;
	font-size: 15px !important;
}
.webchat__markdown__external-link-icon {
	display: none;
}
.webchat__bubble__nub-pad {
	width: 0px !important;
}
.webchat__send-box__main .webchat__icon-button.webchat__icon-button--stretch {
	width: 60px !important;
	border-radius: 25px;
	background-color: #3b96f2;
	background: #3b96f2;
}
.webchat__upload-button.webchat__send-box__button {
	display: inline-block;
	position: relative;
	animation: glowing 2s infinite;
	margin: 0 6px !important;
}
.webchat__upload-button .webchat__icon-button.webchat__icon-button--stretch {
	width: 35px;
	border-radius: 25px;
	padding: 2px;
}
form.webchat__send-box-text-box.webchat__send-box__text-box {
	padding: 5px !important;
}
@keyframes glowing {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 122, 252, 0.7);
	}
	100% {
		box-shadow: 0 0 0 10px rgba(0, 122, 252, 0);
	}
}
.webchat__typingIndicator {
	color: #1e90ff;
	font-size: 12px;
	margin-bottom: 2px;
	margin-left: 15px;
	font-weight: bold;
}
.webchat__scrollToEndButton {
	background: red !important;
	color: #fff !important;
}
.retry-btn {
	color: #1e90ff;
}
span.count {
	position: absolute;
	background: #e74c3c;
	font-size: 10px;
	padding: 0 5px;
	color: #fff;
	border-radius: 100px;
	right: -3px;
	height: 15px;
	width: auto;
}
.btn-notification svg.svg-inline--fa.fa-bell.fa-w-14 {
	font-size: 22px;
	position: relative;
	top: 2px;
}
span.restart-icon.btn-notification {
	position: relative;
}
span.restart-icon.btn-notification {
	display: none;
}
.upload-enabled .webchat__send-box-text-box {
	margin-left: 46px;
}
.webchat__toaster.webchat__toaster--info {
	max-height: 105px !important;
}
/* .file-upload{
  position: relative;
  left: 7px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  border: 0px;
  top: -11px;
  background: #3b96f2;
  color: #fff;
  cursor: pointer;
} */

@keyframes rotate-loading {
	0% {
		transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
	}
}

/* .loader-animation{
  border: 3px solid transparent;
  border-color: transparent #3b96f2 transparent #3b96f2;
  -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
  -moz-transform-origin: 50% 50%;
  -o-animation: rotate-loading 1.5s linear 0s infinite normal;
  -o-transform-origin: 50% 50%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  background: transparent;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  position: absolute;
  top: -23px !important;
  left: 1px;
} */

.wrapper-upload {
	position: relative;
}
.webchat__stacked-layout--from-user .webchat__fileContent__fileName {
	color: #fff !important;
}
.maximize-bot.chat-widget-full .file-upload {
	left: 8.2%;
	top: -42px;
}
.chat-widget-full .wrapper-upload {
	position: relative;
	width: 100%;
	display: inline-block;
}
.chat-widget-full .file-upload {
	left: 18.5%;
	top: -42px;
}
.chat-widget-full .webchat__send-box__text-box {
	margin-left: 38px;
}
.maximize-bot.chat-widget-full .loader-animation {
	left: 8.2%;
	top: -42px;
}
.chat-widget-full .loader-animation {
	left: 18.5%;
	top: -42px;
}

/* NOTE: causes some image to be gigantic
body img.ac-image {
	min-width: auto !important;
}
.chat-container.maximize-bot img.ac-image.ac-selectable {
	min-width: auto !important;
}

// updated css for product level
body img.ac-image.ac-selectable {
	min-width: auto !important;
}
*/

/* -- widget2-3-0 
body img.ac-image.ac-selectable {
	min-width: auto !important;
}
body img.ac-image {
	min-width: auto !important;
} */

.chat-container .welcome-user {
	display: none;
}
/* -- widget2-3-0
.chat-container.maximize-bot img.ac-image.ac-selectable {
	min-width: auto !important;
} */

.welcome-user {
	display: none;
}
.webchat__basic-transcript .webchat__basic-transcript__activity-box:not(:empty) {
	padding-bottom: 0 !important;
}
.enabled-upload .webchat__send-box__main input.webchat__send-box-text-box__input {
	margin-left: 39px;
}

/* botframework-webchat v^4.15.7 upgrade has an ugly looking shade mask that appears on hover.
// Here we disable it to keep the same look and feel as prior to the upgrade */
.chat-container
	button.webchat__icon-button.webchat__send-button:not(:disabled):not([aria-disabled='true']):hover
	.webchat__icon-button__shade {
	background-color: transparent;
}

/*
//
// microtip tooltip styles
:root {
	// --microtip-font-size: 14px;
	// --microtip-transition-duration: 0.25s;
	// --microtip-transition-delay: 0.1s;
}
*/

[aria-label][role~='tooltip']::after {
	background: rgba(17, 17, 17, 0.85);
	padding: 0.5em 0.6em;
}

@media screen and (max-width:440px) { img.ac-image { min-width: 100% !important; } #botChatDiv .chat-container img.ac-image { min-width: 100% !important; } }

@media screen and (max-width:400px) {  img.ac-image { min-width: 100% !important; } #botChatDiv .chat-container img.ac-image { min-width: 100% !important; } }

@media screen and (max-width:360px) {  img.ac-image { min-width: 100% !important; } #botChatDiv .chat-container img.ac-image { min-width: 100% !important; } }

.ac-container.ac-adaptiveCard {
	margin: 0px !important;
}

#botChatDiv .chat-container img.ac-image {
	min-width: 100% !important;
}

.chat-container-aibot {
	transform: translateY(0px) !important;
	/* width: 63.8% !important; */
	width:69.8% !important;
	border-right: 1px solid #D1D5DB;
	box-shadow: unset;
	border-radius: unset;
}

.chat-container-aibot.maximize-bot {
	height: 92% !important;
	right: unset;
}
.chat-container-aibot .click-begin{
	margin-top: 30px !important;
  }